/* @flow */

import * as React from 'react'
import { LegalDocumentTemplate } from '../templates'
import text from './../../static/markdowns/conditions-generales-dutilisation.md'

type Props = {
  location: {
    href: string,
  },
}

export default function ConditionsGeneralesDutilisation(props: Props) {
  const { location } = props
  return (
    <LegalDocumentTemplate
      url={location.href}
      title="Conditions générales d'utilisation"
      name="Wino Conditions générales d'utilisation"
    >
      {text}
    </LegalDocumentTemplate>
  )
}
